import { Heading, Logo } from '@mediahuis/chameleon-react';
import { MutableRefObject, useRef } from 'react';
import cx from 'classnames';
import { articleLayoutConfig, heroBlockHeadingProps } from '@hubcms/brand';

import { usePassThrough } from '../../../hooks/usePassThrough';

import styles from './story-headline.module.scss';
import { StoryHeadlineProps } from './types';

function StoryHeadline({ className, articleId, headingText, isPremium = false, ...headingProps }: StoryHeadlineProps) {
  const headlineRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  usePassThrough({ articleId, headlineRef });

  return (
    <div className={styles.storyHeadline}>
      <Heading
        data-testid="article-headline"
        className={cx(className, styles.storyHeadlineHeading)}
        color="var(--x-story-headline-color, var(--story-headline-color, inherit))"
        level={1}
        {...heroBlockHeadingProps}
        {...headingProps}
        ref={headlineRef}
      >
        {articleLayoutConfig.heroblockPremiumIconLocation === 'heading' && isPremium && (
          <Logo name="logo-premium-main" className={cx(styles.premiumIcon)} />
        )}
        {headingText}
      </Heading>
    </div>
  );
}

export default StoryHeadline;
